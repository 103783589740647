<template>
  <template v-if="data">
    <section v-if="data.crumbs.length" class="o-navigation-breadcrumbs">
      <div class="container">
        <div class="o-navigation-breadcrumbs__container">
          <Button
            v-for="crumb in data.crumbs"
            :key="`${crumb}`"
            :tag="crumb.link ? 'a' : 'span'"
            :disabled="!crumb.link"
            :href="crumb.link"
            :title="crumb.title"
            theme="light"
            modifier="quaternary"
            size="small"
          >
            {{ crumb.title }}
          </Button>
        </div>
      </div>
    </section>
  </template>

  <section v-else-if="!websiteConfig.modes.isProduction">
    <UtilsDataMissing :data="props.data" />
  </section>
</template>

<script setup lang="ts">
// Components
import UtilsDataMissing from '~/components/Utils/Data/Missing/UtilsDataMissing.vue';
import Button from '@ice-products-ui/icecorp-vue-library/Button';

// Composables
import useComponentSchemaValidator from '~/composables/validators/useComponentSchemaValidator';

// Types
import type { IComponentProps } from '~/typings/types/component.types';
import type { TONavigationBreadcrumbs } from '~/components/O/Navigation/Breadcrumbs/ONavigationBreadcrumbs.types';
import type { TWebsiteConfig } from '~/typings/types/website-config.types';

// Schema
import schema from '~/components/O/Navigation/Breadcrumbs/ONavigationBreadcrumbs.schema';

defineOptions({
  name: 'OBreadcrumbsDefault',
});

const props = withDefaults(defineProps<IComponentProps<TONavigationBreadcrumbs>>(), {
  viewName: 'OBreadcrumbsDefault',
});

const url = useRequestURL();
const websiteConfig = useState<TWebsiteConfig>('config');

const data = props.data.component;

useComponentSchemaValidator(schema, props);

if (data.value && data.value.crumbs.length) {
  useHead({
    script: [
      {
        type: 'application/ld+json',
        innerHTML: JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: data.value.crumbs.map((crumb, idx) => ({
            '@type': 'ListItem',
            position: idx + 1,
            name: crumb.title,
            item: crumb.link ? url.origin + crumb.link : undefined,
          })),
        }),
      },
    ],
  });
}
</script>
